export enum ExpertRole {
  ClinicalReviewer = 'CLINICAL_REVIEWER',
  LegalReviewer = 'LEGAL_REVIEWER',
  IntegratedReviewer = 'INTEGRATED_REVIEWER',
}

export const ExpertRoleName = {
  [ExpertRole.ClinicalReviewer]: 'Clinical Reviewer',
  [ExpertRole.LegalReviewer]: 'Legal Reviewer',
  [ExpertRole.IntegratedReviewer]: 'Integrated Reviewer',
}