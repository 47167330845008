import {
  boolean,
  date, mixed, number, object, SchemaOf, string,
} from 'yup';
import { Organization } from '@declarations/models/Organization';
import Errors from '@constants/locale/errors';
import { OrganizationType } from '@declarations/common/enums/OrganizationType';
import '@utils/yup-methods';
import { array } from 'yup';

type UpsertOrganizationDTO = Omit<Organization, 'id' | 'createdAt'> & Pick<Partial<Organization>, 'id' | 'createdAt'>;

const organizationFormValidationSchema: SchemaOf<UpsertOrganizationDTO> = object().shape({
  id: number(),
  createdAt: date(),
  name: string().required(Errors.REQUIRED_FIELD),
  prefix: string().required(Errors.REQUIRED_FIELD),
  type: mixed().required(Errors.REQUIRED_FIELD).oneOf(Object.values(OrganizationType), 'Please select the type of Organization'),
  primaryContact: string().nullable().notRequired(),
  primaryContactEmail: string().notRequired().nullable().email(Errors.VALID_EMAIL),
  contactNumber: string().when('contactNumber', {
    is: (value: string) => value?.length > 0,
    then: string().phone('US', false, Errors.VALID_PHONE),
    otherwise: string().notRequired().nullable(),
  }),
  city: string().notRequired().nullable(),
  state: string().notRequired().nullable(),
  zipCode: string().notRequired().zipCode('US').nullable(),
  physicalAddress: string().notRequired().nullable(),
  isPhysicalAndMailingAddressEqual: boolean().required(),
  mailingAddress: string().nullable(),
  mailingAddressCity: string().nullable(),
  mailingAddressState: string().nullable(),
  mailingAddressZipCode: string().zipCode('US').nullable(),
  additionalInfo: string().notRequired().nullable(),
  providerTypes: array().min(1, Errors.REQUIRED_FIELD) as any,
}, [['contactNumber', 'contactNumber']]);

export default organizationFormValidationSchema;
