import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FacilityService from '@api/facilityService';
import { toast } from 'react-toastify';
import { Facility } from '@declarations/models/Facility';
import { FormMode } from '@hooks/useFormEdit';
import OrganizationService from '@api/organizationService';
import CRUDForm from '@components/CRUDForm';
import routes from '@constants/routes';
import useAccess from '@hooks/useAccess';
import { UserType } from '@declarations/common/enums/UserType';
import facilityFormValidationSchema from './FacilityForm.validation-schema';
import UserService from '@api/userService';
import { UserActions } from '@store/slices/user';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsActions, SettingsSelectors } from '@store/slices/settings';
import ProviderTypeService from '@api/providerTypeService';
import { Option } from '@declarations/common/option';
import { providerTypeLabels } from '../const';
import { OrganizationName } from '@declarations/models/OrganizationName';
import useGenerateFields from './FacilityForm.fields';

function FacilityForm() {
  const { hasAccess: hasSuperAdminAccess } = useAccess({ types: [UserType.SuperAdmin] });
  const navigate = useNavigate();
  const [organizationOptions, setOrganizationOptions] = useState<Option<string>[]>([]);
  const [organizations, setOrganizations] = useState<OrganizationName[]>([]);
  const dispatch = useDispatch();
  const selectedFacility = useSelector(SettingsSelectors.selectedFacility);
  const [providerTypeOptions, setProviderTypeOptions] = useState<Option<string>[]>([]);

  const fetchOrganizations = async () => {
    const response = await OrganizationService.getNames();

    if (response.data) {
      setOrganizations(response.data);
      setOrganizationOptions(response.data.map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      })));
    }
  };

  const fetchProviderTypes = async () => {
    const { data } = await ProviderTypeService.list();

    if (data) {
      setProviderTypeOptions(data.map(({ type }) => ({
        value: type,
        label: providerTypeLabels[type],
      })));
    }
  }

  useEffect(() => {
    fetchOrganizations();
    fetchProviderTypes();
  }, []);

  const generateFields = useGenerateFields({ organizationOptions, providerTypeOptions, organizations })

  const onSubmit = async (data: Facility | FormData) => {
    await FacilityService.upsert(data);
    const { data: me } = await UserService.me();
    dispatch(UserActions.setUser(me));
    if (selectedFacility?.id && selectedFacility.id === (data as Facility).id) {
      const facility = me.facilities.find(f => f.id === selectedFacility.id);
      if (facility) {
        dispatch(SettingsActions.setFacility(facility));
      }
    }
    const facilityName = (data as Facility).name || 'Facility';

    navigate(routes.facilities);
    toast.success(`${facilityName} has been saved successfully`);
  };

  return (
    <CRUDForm
      defaultValues={{
        primaryContact: '',
        primaryContactEmail: '',
        contactNumber: '',
        city: '',
        state: '',
        zipCode: '',
        physicalAddress: '',
        isPhysicalAndMailingAddressEqual: false,
        mailingAddress: '',
        mailingAddressCity: '',
        mailingAddressState: '',
        mailingAddressZipCode: '',
        additionalInfo: '',
      }}
      validationSchema={facilityFormValidationSchema}
      fetchEntityMethod={FacilityService.getById}
      renderTitle={
        (form, mode) => (mode === FormMode.Create
          ? <>New Facility</>
          : (
            <>
              Facility:
              {' '}
              {form.watch('name')}
            </>
          ))
      }
      onSubmit={onSubmit}
      fields={generateFields}
      backRoute={routes.facilities}
      editDisabled={!hasSuperAdminAccess}
    />
  );
}

export default FacilityForm;
