import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import { useMemo } from 'react';

const useReportPinnedColumns = (expertRole?: ExpertRole): string[] | undefined => {
  return useMemo(() => {
    switch (expertRole) {
      case ExpertRole.ClinicalReviewer:
        return ['clinicalReviewCompleted'];
      case ExpertRole.LegalReviewer:
        return ['legalReviewCompleted'];
      case ExpertRole.IntegratedReviewer:
        return ['clinicalReviewCompleted', 'legalReviewCompleted'];
      default:
        break;
    }
  }, [expertRole]);
};

export default useReportPinnedColumns;
