import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import { LogRecord } from '@declarations/models/LogRecord';
import { ReviewerSnapshot } from '@declarations/models/ReviewerSnapshot';
import { AxiosResponse } from 'axios';

export default function setLogRecordExpertUsers(
  res: AxiosResponse<LogRecord[]>,
): AxiosResponse<any[]> {
  res.data = res.data?.map((item: LogRecord) => {
    const clinicalReviewer = item.reviewersSnapshot.find(
      (r: ReviewerSnapshot) => r.assignedReviewerRole === ExpertRole.ClinicalReviewer,
    )?.userSnapshot;
    const legalReviewer = item.reviewersSnapshot.find(
      (r: ReviewerSnapshot) => r.assignedReviewerRole === ExpertRole.LegalReviewer,
    )?.userSnapshot;
    return {
      ...item,
      clinicalReviewer,
      legalReviewer,
    };
  });

  return res;
}
