import TableToolbar from '@components/TableToolbar';
import MedicalService from '@api/MedicalRRService';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import routes from '@constants/routes';
import { DataGrid, useGridModels } from '@components/DataGrid';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import useAccess from '@hooks/useAccess';
import { UserType } from '@declarations/common/enums/UserType';
import useGridData from '@components/DataGrid/hooks/useGridData';
import { MedicalRecordRequest } from '@declarations/models/reports/MedicalRecordRequest';
import { toast } from 'react-toastify';
import getDifferent from '@utils/getDifferent';
import pick from 'lodash/pick';
import getColumns from './columns';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import useExpertUsers from '@hooks/useExpertUsers';
import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import { ReportTypeEnum } from '@declarations/common/reportType';
import { SessionSelectors } from '@store/slices/session';
import { DashboardMode } from '@declarations/common/enums/DashboardMode';
import useReportPinnedColumns from '@hooks/useReportPinnedColumns';

type MedicalReportsGridProps = {
  medicalsReportGridKey: string;
  onRemove?: (ids: number[]) => void;
  onMRRPatched?: (newValues: Partial<MedicalRecordRequest>) => Promise<void>;
  gridFilterItem?: GridFilterItem;
};

function Index(props: MedicalReportsGridProps) {
  const userType = useSelector(UserSelectors.type);
  const dashboardMode = useSelector(SessionSelectors.dashboardMode);
  const {hasAccess: hasRemovalAccess} = useAccess({types: [UserType.SuperAdmin]});
  const gridModels = useGridModels();
  const fetchList = useCallback(MedicalService.listWithRole(dashboardMode), [dashboardMode]);
  const fetchCSV = useCallback(MedicalService.exportCSVWithRole(dashboardMode), [dashboardMode]);
  const {data, getData, loading} = useGridData({fetchMethod: fetchList, gridModels});
  const user = useSelector(UserSelectors.user);
  const {clinicalReviewers, legalReviewers, usersLoading, setPromiseArguments, renderConfirmDialog} = useExpertUsers(user.type);

  const onRemove = async () => {
    const ids = gridModels.rowSelectionModel as number[];
    await MedicalService.remove(ids);
    gridModels.setPage(0);
    gridModels.setSelection([]);

    await getData();

    props?.onRemove?.(ids);
  };

  const onInlineEdit = useCallback(async (newRow: MedicalRecordRequest, oldRow: MedicalRecordRequest) => {
    const different = getDifferent(oldRow, newRow);
    const newValues = pick(newRow, different);

    if (different[0] === 'clinicalReviewer' || different[0] === 'legalReviewer') {
      return new Promise<MedicalRecordRequest>((resolve, reject) => {
        const expertRole = different[0] === 'clinicalReviewer' ? ExpertRole.ClinicalReviewer : ExpertRole.LegalReviewer;
        setPromiseArguments({newRow, oldRow, reject, resolve, expertRole, reportType: ReportTypeEnum.MedicalRR});
      });
    }

    if (Object.keys(newValues).length) {
      await toast.promise(MedicalService.patch(newRow.id, newValues), {
        pending: 'Updating...',
        success: 'Successfully updated',
        error: 'Something went wrong',
      });
      if (newValues.calculatedStatus !== undefined) {
        getData();
      }
      props?.onMRRPatched?.(newValues);
    }

    return newRow;
  }, [data]);

  const areColumnsEditable = useMemo(() => userType === UserType.SuperAdmin, [userType]);
  const columns = useMemo(() => getColumns(
      {userType, clinicalReviewers, legalReviewers, inlineEditEnabled: areColumnsEditable}),
    [userType, clinicalReviewers, legalReviewers],
  );

  const pinnedColumns = useReportPinnedColumns(user.expertRole);

  return (
    <>
      {renderConfirmDialog()}

      <TableToolbar
        onRemove={onRemove}
        exportCSVFile={{
          fileName: () => `medical-records-${new Date().toLocaleDateString('en-US')}.csv`,
          fetchMethod: fetchCSV,
          fetchParams: gridModels.getFetchListParams(),
        }}
        showRemove={gridModels.getSelectionCount() > 0}
        removalModalTitle="Are you sure you want to delete selected reports?"
        removalModalContent={`You selected ${gridModels.getSelectionCount()} report(s) to delete.`}
      >
        {(!user.expertRole || dashboardMode === DashboardMode.LTC) && <Button component={Link} variant="contained" to={routes.medicalRecordRequestForm}>
            New Medical Record Request
        </Button>}
      </TableToolbar>

      <DataGrid
        showQuickFilter
        loading={loading}
        rows={data}
        columns={columns}
        checkboxSelection={hasRemovalAccess}
        processRowUpdate={onInlineEdit}
        stateName={props.medicalsReportGridKey}
        gridFilterItem={props.gridFilterItem}
        gridHeight={698}
        pinnedColumns={pinnedColumns}
        usersLoading={usersLoading}
        {...gridModels}
      />
    </>
  );
}

export default Index;
