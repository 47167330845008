import routes from '@constants/routes';
import { User } from '@declarations/models/User';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FacilityService from '@api/facilityService';
import { toast } from 'react-toastify';
import { FormMode } from '@hooks/useFormEdit';
import Button from '@components/Button';
import useAccess from '@hooks/useAccess';
import { UserType } from '@declarations/common/enums/UserType';
import RemoveRowsModal from '@components/modals/RemoveRowsModal';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices/user';
import { UseFormReturn } from 'react-hook-form';
import { Option } from '@declarations/common/option';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OrganizationService from '../../api/organizationService';
import UserService from '../../api/userService';
import CRUDForm from '../../components/CRUDForm';
import userManagementFormValidationSchema from './utils/validationSchemas/userManagementForm';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { ExpertRole, ExpertRoleName } from '@declarations/common/enums/ExpertRole';

function UserManagementForm() {
  const navigate = useNavigate();

  const userType = useSelector(UserSelectors.type);
  const { hasAccess } = useAccess({ types: [UserType.SuperAdmin] });
  const [removeRowsModalOpen, setRemoveRowsModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState<{ type: 'soft' | 'hard'; id: number; loading: boolean }>({
    type: 'soft',
    id: -1,
    loading: false,
  });
  const [organizations, setOrganizations] = useState<Option[]>([]);
  const [facilities, setFacilities] = useState<(Option & { organization: number })[]>([]);

  const fetchOrganizations = async () => {
    const response = await OrganizationService.getNames();

    if (response.data) {
      setOrganizations(response.data.map(({ id, name }) => ({
        value: id,
        label: name,
      })));
    }
  };

  const fetchFacilities = async () => {
    const response = await FacilityService.getNames();

    if (response?.data) {
      setFacilities(response.data.map(({ id, name, organization }) => ({
        value: id,
        label: name,
        organization,
      })));
    }
  };

  const onSubmit = async (data: User | FormData) => {
    await UserService.upsert(data);

    const user = (data as User);
    const userName = user.firstname && user.lastname ? `User ${user.firstname} ${user.lastname}` : 'User';
    navigate(routes.userManagement);
    toast.success(`${userName} has been saved successfully`);
  };

  const onDelete = (id: number, type: 'soft' | 'hard') => () => {
    setDeleteUser({ ...deleteUser, type, id });
    setRemoveRowsModalOpen(true);
  };

  const onDeleteConfirm = async () => {
    try {
      setDeleteUser({ ...deleteUser, loading: true });
      const deleteMethod = deleteUser.type === 'soft' ? UserService.disable : UserService.delete;
      await deleteMethod(deleteUser.id);

      setRemoveRowsModalOpen(false);

      navigate(routes.userManagement);
      toast.success(`User successfully ${deleteUser.type === 'soft' ? 'disabled' : 'deleted'}`);
    } catch (error: any) {
      if (error?.response?.status === 400 && error.response.data?.message) {
        toast.error(error?.response?.data.message);
      } else {
        toast.error('Something went wrong');
      }
    } finally {
      setDeleteUser({ ...deleteUser, loading: false });
    }
  };

  const onEnable = (userId: number) => async () => {
    await UserService.disable(userId);

    navigate(routes.userManagement);
    toast.success('User successfully enabled');
  };

  useEffect(() => {
    fetchOrganizations();
    fetchFacilities();
  }, []);

  const generateFields = (form: UseFormReturn<User>, mode: FormMode): Fields<User> => {
    return [
      [
        {
          type: 'text', required: true, name: 'email', label: 'Email', props: { disabled: mode !== FormMode.Create },
        },
      ],
      [
        {
          type: 'text', required: true, name: 'firstname', label: 'First Name',
        },
        {
          type: 'text', required: true, name: 'lastname', label: 'Last Name',
        },
      ],
      [
        {
          type: 'text', required: true, name: 'position', label: 'Position',
        },
      ],
      [
        {
          type: 'select',
          multiple: true,
          required: userType === UserType.OrganizationAdmin,
          name: 'organizations',
          label: 'Organization',
          options: organizations,
          props: {
            onChange: (e: any) => {
                const value: any[] = e.target.value || [];
                form.setValue('organizations', value);
                const facilityIds = form.watch('facilities')?.filter(facilityId => {
                  const facility = facilities?.find(f => f.value === facilityId);
                  return value.includes(facility?.organization);
                });
                form.setValue('facilities', facilityIds);
            },
          },
        },
        {
          type: 'select',
          multiple: true,
          required: userType === UserType.OrganizationAdmin && mode !== FormMode.View,
          name: 'facilities',
          label: 'Facility',
          options: facilities.filter(
            (facility) => form.watch('organizations').includes(facility.organization as unknown as string),
          ),
        },
      ],
      [
        {
          type: 'checkbox',
          name: 'expertUser',
          label: 'Expert User',
          props: {
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              form.setValue('expertUser', e.target.checked as any);
              if (!e.target.checked) {
                form.setValue('expertRole', null);
              }
            },
          },
        },
        {
          type: 'select',
          required: false,
          name: 'expertRole',
          label: 'Expert Role',
          options: [
            {label: ExpertRoleName[ExpertRole.ClinicalReviewer], value: ExpertRole.ClinicalReviewer},
            {label: ExpertRoleName[ExpertRole.LegalReviewer], value: ExpertRole.LegalReviewer},
            {label: ExpertRoleName[ExpertRole.IntegratedReviewer], value: ExpertRole.IntegratedReviewer},
          ],
          hidden: !form.watch('expertUser', false)
        }
      ],
      [
        {
          type: 'text', name: 'preferredContactNumber', label: 'Preferred contact number',
        },
      ],
      [
        {
          type: 'text',
          name: 'additionalInfo',
          label: 'Additional Information',
          props: {
            rows: 2,
            multiline: true,
          },
        },
      ],
    ];
  }

  return (
    <>
      <CRUDForm
        moreActions={(form: UseFormReturn<User>, mode: FormMode) => {
          const userId = form.watch('id');
          const isUserDisabled = form.watch('disabled');

          if (!userId || !hasAccess) return undefined;

          return [
            {
              title: isUserDisabled ? 'Enable' : 'Disable',
              onClick: isUserDisabled ? onEnable(userId) : onDelete(userId, 'soft'),
            },
            {
              title: 'Delete',
              onClick: onDelete(userId, 'hard'),
            },
          ];
        }}
        editDisabled={
          (form) => (form.watch('type') === UserType.OrganizationAdmin && userType === UserType.OrganizationAdmin)
        }
        defaultValues={{
          facilities: [],
          organizations: [],
        }}
        validationSchema={userManagementFormValidationSchema({ userType })}
        fetchEntityMethod={UserService.getById}
        renderTitle={(form, mode) => (mode === FormMode.Create ? (
          <>
            New
            User
          </>
        ) : (
          <>
            User:
            {' '}
            {form.watch('firstname')}
            {' '}
            {form.watch('lastname')}
          </>
        ))}
        onSubmit={onSubmit}
        fields={generateFields}
        backRoute={routes.userManagement}
      />

      <RemoveRowsModal
        isOpen={removeRowsModalOpen}
        handleClose={() => setRemoveRowsModalOpen(false)}
        handleConfirm={onDeleteConfirm}
        disableConfirmationToast
        title={`Are you sure you want to ${deleteUser.type === 'soft' ? 'disable' : 'delete'} this user?`}
        content="This user will not be able to log into the system."
      />
    </>
  );
}

export default UserManagementForm;
