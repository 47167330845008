import { ReportType } from '@declarations/common/reportType';
import initInstance from './axios';

const api = initInstance('/provider-log-record');

type CreateProviderLogRecord = {
  subject?: string;
  note?: string;
}

type QueryProviderLogRecords = {
  reportId: number;
  reportType: ReportType;
}

const ProviderLogRecordService = {
  createRecord: (data: CreateProviderLogRecord | FormData) => api.post('/create-record', data),
  get: (params: QueryProviderLogRecords) =>
    api.get<any[]>('', { params }),
  
};

export default ProviderLogRecordService;