import LawsuitService from '@api/LawsuitService';
import ClinicalLogRecord from '@components/SubmissionTabs/ClinicalLogRecord';
import ClinicalReviewForm from '@components/SubmissionTabs/ClinicalReviewForm';
import ReportHeader from '@components/SubmissionTabs/ReportHeader';
import SubmissionTabs from '@components/SubmissionTabs/SubmissionTabs';
import routes from '@constants/routes';
import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import { UserType } from '@declarations/common/enums/UserType';
import { ReportTypeEnum } from '@declarations/common/reportType';
import useApi from '@hooks/useApi';
import { UserSelectors } from '@store/slices/user';
import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

function LawsuitClinicalReviewTab() {
  const [searchParams] = useSearchParams();
  const user = useSelector(UserSelectors.user);
  const logRecordRef = useRef<{ refresh(): void }>(null);

  const { data, isLoading, fetch } = useApi({
    method: LawsuitService.getById,
    initialData: {} as any,
  });

  useEffect(() => {
    fetch(Number(searchParams.get('id')));
  }, []);

  const handleSave = () => {
    logRecordRef.current?.refresh();
  }
  
  const showForm = user.type === UserType.SuperAdmin || user.expertRole === ExpertRole.ClinicalReviewer || user.expertRole === ExpertRole.IntegratedReviewer;

  return (
    <>
      <SubmissionTabs active="clinical-review" prefix={routes.lawsuitForm} />
      <ReportHeader
        name="Lawsuit Notification"
        createdAt={data.createdAt}
        reportNumber={data.reportNumber}
        riskPoints={10}
        loading={isLoading}
      />
      {showForm && <ClinicalReviewForm reportType={ReportTypeEnum.Lawsuit} onSave={handleSave} />}
      <ClinicalLogRecord reportType={ReportTypeEnum.Lawsuit} ref={logRecordRef} />
    </>
  );
}

export default memo(LawsuitClinicalReviewTab);
