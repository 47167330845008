import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import HeaderBar from './HederBar';
import { useSearchParams } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import { ReportType } from '@declarations/common/reportType';
import UserLogRecordService from '@api/userLogRecordService';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, SelectChangeEvent } from '@mui/material';
import userFormatter from '@components/DataGrid/renders/userFormatter';
import dateFormatter from '@components/DataGrid/renders/dateFormatter';
import { UserType } from '@declarations/common/enums/UserType';
import { toast } from 'react-toastify';
import ExpertUserService from '@api/ExpertUserService';
import { Add } from '@mui/icons-material';
import SingleSelect from '@components/select/SingleSelect';
import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import LogRecordContainer from './LogRecordContainer';
import TextFieldItem from '@components/TextFieldItem';
import useApi from '@hooks/useApi';

type Props = {
  reportType: ReportType;
  userType: UserType;
};

const columns: GridColDef<any>[] = [
  {
    field: 'accountRepSnapshot',
    headerName: 'Account Rep',
    flex: 0.5,
    valueFormatter: userFormatter,
  },
  {
    field: 'clinicalReviewer',
    headerName: 'Clinical Reviewer',
    flex: 0.5,
    valueFormatter: userFormatter,
  },
  {
    field: 'legalReviewer',
    headerName: 'Legal Reviewer',
    flex: 0.5,
    valueFormatter: userFormatter,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    flex: 0.5,
    valueFormatter: dateFormatter,
  },
  {
    field: 'note',
    headerName: 'Notes',
    flex: 1,
  },
];

type Model = {
  reportId: number;
  reportType: ReportType;
  clinicalReviewer?: number;
  legalReviewer?: number;
  note?: string;
};

export default function UserLogRecord({ reportType, userType }: Props) {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clinicalReviewers, setClinicalReviewers] = useState<any[]>([]);
  const [legalReviewers, setLegalReviewers] = useState<any[]>([]);
  const id = Number(searchParams.get('id'));
  const [model, setModel] = useState<Model>({ reportId: id, reportType });
  const { data: expertUsers, isLoading: usersLoading, fetch: getUsers } = useApi({
    method: ExpertUserService.get,
    initialData: [],
  });

  const fetchRecord = async () => {
    if (!id) {
      return;
    }
    setLoading(true);
    const response = await UserLogRecordService.get({ reportId: Number(id), reportType });
    setData(response.data);
    setModel(state => ({
      ...state,
      clinicalReviewer: response.data[0]?.clinicalReviewer?.user_id,
      legalReviewer: response.data[0]?.legalReviewer?.user_id,
    }));
    setLoading(false);
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchRecord();
  }, [id]);

  useEffect(() => {
    setClinicalReviewers([
      { value: '', label: <em>None</em> },
      ...expertUsers
        .filter((item: any) => item.expertRole === ExpertRole.ClinicalReviewer || item.expertRole === ExpertRole.IntegratedReviewer)
        .map((item: any) => ({ value: item.id, label: `${item.firstname} ${item.lastname}` })),
    ]);

    setLegalReviewers([
      { value: '', label: <em>None</em> },
      ...expertUsers
        .filter((item: any) => item.expertRole === ExpertRole.LegalReviewer || item.expertRole === ExpertRole.IntegratedReviewer)
        .map((item: any) => ({ value: item.id, label: `${item.firstname} ${item.lastname}` })),
    ]);

    debugger
  }, [expertUsers]);

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => setModel(state => ({ ...state, note: e.target.value }));

  const closeModal = () => {
    setShowModal(false);
    setModel(state => ({
      ...state,note: undefined,
      clinicalReviewer: data[0]?.clinicalReviewer?.user_id,
      legalReviewer: data[0]?.legalReviewer?.user_id,
    }));
    setModalLoading(false);
  };

  const handleNo = () => {
    closeModal();
  };

  const handleYes = async () => {
    try {
      setModalLoading(true);
      await toast.promise(
        ExpertUserService.upsertReportAssignments({
          clinicalReviewers: model.clinicalReviewer ? [model.clinicalReviewer] : [],
          legalReviewers: model.legalReviewer ? [model.legalReviewer] : [],
          reportId: id,
          reportType,
          note: model.note,
        }),
        {
          pending: 'Updating...',
          success: 'Successfully updated',
          error: 'Something went wrong',
        },
      );
      closeModal();
      fetchRecord();
    } catch (error) {
      closeModal();
    }
  };

  const handleChangeClinicalReviewer = (e: SelectChangeEvent<unknown>) => {
    setModel(state => ({...state, clinicalReviewer: Number(e.target.value)}));
  };

  const handleChangeLegalReviewer = (e: SelectChangeEvent<unknown>) => {
    setModel(state => ({...state, legalReviewer: Number(e.target.value)}));
  };

  const handleAddNew = () => {
    setShowModal(true);
    if (clinicalReviewers?.length > 1 || legalReviewers?.length > 1) {
      return;
    }
    getUsers();
  };

  const actions =
    userType === UserType.SuperAdmin ? (
      <Button
        variant="outlined"
        color="inherit"
        disabled={loading}
        onClick={handleAddNew}
      >
        <Add sx={{ mr: '6px' }} />
        Add New
      </Button>
    ) : undefined;

  if (!id) {
    return null;
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={showModal}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          Add New
          {usersLoading && <CircularProgress sx={{ maxWidth: '1rem', maxHeight: '1rem', ml: '8px' }} />}
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '16px',
              marginBottom: '24px',
            }}
          >
            <SingleSelect
              options={clinicalReviewers}
              value={model.clinicalReviewer}
              label="Clinical Reviewer"
              onChange={handleChangeClinicalReviewer}
            />
            <SingleSelect
              options={legalReviewers}
              value={model.legalReviewer}
              label="Legal Reviewer"
              onChange={handleChangeLegalReviewer}
            />
          </div>
          <TextFieldItem
            fullWidth
            multiline
            label="Notes"
            value={model.note}
            onChange={handleNoteChange}
            minRows={3}
            inputProps={{ maxLength: 180 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} disabled={modalLoading}>
            SAVE
          </Button>
          <Button onClick={handleNo}>CANCEL</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ mt: '32px' }}>
        <HeaderBar title="Log Record" actions={actions} />
        <LogRecordContainer rows={data.length}>
          <DataGridPro
            rows={data}
            autoHeight={data.length <= 5}
            rowSelection={false}
            disableColumnMenu
            disableColumnFilter
            columns={columns}
            loading={loading}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
                whiteSpace: 'pre-line',
              },
            }}
            getRowHeight={() => 'auto'}
          />
        </LogRecordContainer>
      </Box>
    </>
  );
}
