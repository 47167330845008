import { Option } from '@declarations/common/option';
import { Facility } from '@declarations/models/Facility';
import { UseFormReturn } from 'react-hook-form';
import { FormMode } from '@hooks/useFormEdit';
import { Fields } from '@components/CRUDForm/declarations/FormField';
import { statesUSA } from '../../consts';
import { OrganizationName } from '@declarations/models/OrganizationName';
import { providerTypeLabels } from '../const';
import { useCallback } from 'react';

type GenerateFieldParams = {
  organizationOptions: Option[];
  providerTypeOptions: Option[];
  organizations: OrganizationName[];
};

function useGenerateFields({
  organizationOptions,
  providerTypeOptions,
  organizations,
}: GenerateFieldParams) {
  return useCallback(
    (form: UseFormReturn<Facility>, mode: FormMode): Fields<Facility> => {
      let availableProviderTypes = providerTypeOptions;
      const organizationId = form.watch('organization');

      if (organizationId) {
        availableProviderTypes =
          organizations
            .find(o => o.id == organizationId)
            ?.providerTypes.map(({ type }) => ({
              value: type,
              label: providerTypeLabels[type],
            })) || [];

        const currentType = form.watch('type');
        if (currentType && !availableProviderTypes.find(({ value }) => value === currentType)) {
          form.setValue('type', undefined);
        }
      }

      return [
        [
          {
            type: 'text',
            required: true,
            name: 'name',
            label: 'Facility Name',
          },
          {
            type: 'select',
            required: true,
            name: 'type',
            label: 'Facility Types',
            options: availableProviderTypes,
          },
        ],
        [
          {
            type: 'select',
            required: true,
            name: 'organization',
            label: 'Organization',
            options: organizationOptions,
          },
        ],
        [
          {
            type: 'text',
            required: true,
            name: 'primaryContact',
            label: 'Primary Contact',
          },
          {
            type: 'text',
            required: true,
            name: 'primaryContactEmail',
            label: 'Primary Contact Email',
          },
        ],
        [
          {
            type: 'text',
            required: true,
            name: 'contactNumber',
            label: 'Contact Number',
          },
        ],
        [
          {
            type: 'label',
            label: 'Physical Address',
          },
        ],
        [
          {
            type: 'text',
            required: true,
            name: 'physicalAddress',
            label: 'Address Line',
          },
          {
            type: 'text',
            required: true,
            name: 'city',
            label: 'City',
          },
          {
            type: 'select',
            required: true,
            name: 'state',
            label: 'State',
            options: statesUSA,
          },
          {
            type: 'text',
            name: 'zipCode',
            label: 'Zip Code',
            required: true,
          },
        ],
        [
          {
            type: 'checkbox',
            name: 'isPhysicalAndMailingAddressEqual',
            label: 'Mailing address is the same as the Physical address',
            fullWidth: true,
          },
        ],
        [
          {
            type: 'label',
            label: 'Mailing Address',
            hidden: form.watch('isPhysicalAndMailingAddressEqual'),
          },
        ],
        [
          {
            type: 'text',
            required: true,
            name: 'mailingAddress',
            label: 'Address Line',
            hidden: form.watch('isPhysicalAndMailingAddressEqual'),
            props: {
              value: form.watch('isPhysicalAndMailingAddressEqual')
                ? ''
                : form.watch('mailingAddress'),
            },
          },
          {
            type: 'text',
            required: true,
            name: 'mailingAddressCity',
            label: 'City',
            hidden: form.watch('isPhysicalAndMailingAddressEqual'),
            props: {
              value: form.watch('isPhysicalAndMailingAddressEqual')
                ? ''
                : form.watch('mailingAddressCity'),
            },
          },
          {
            type: 'select',
            required: true,
            name: 'mailingAddressState',
            label: 'State',
            options: statesUSA,
            hidden: form.watch('isPhysicalAndMailingAddressEqual'),
            props: {
              value: form.watch('isPhysicalAndMailingAddressEqual')
                ? ''
                : form.watch('mailingAddressState'),
            },
          },
          {
            type: 'text',
            name: 'mailingAddressZipCode',
            label: 'Zip Code',
            required: true,
            hidden: form.watch('isPhysicalAndMailingAddressEqual'),
            props: {
              value: form.watch('isPhysicalAndMailingAddressEqual')
                ? ''
                : form.watch('mailingAddressZipCode'),
            },
          },
        ],
        [
          {
            type: 'text',
            required: true,
            format: 'number',
            name: 'licensedBedCount',
            label: 'Licensed Bed Count',
          },
          {
            type: 'text',
            required: true,
            format: 'number',
            name: 'averageOccupiedBeds',
            label: 'Average Occupied Beds',
          },
        ],
        [
          {
            type: 'text',
            name: 'additionalInfo',
            label: 'Additional Information',
            props: {
              rows: 2,
              multiline: true,
            },
          },
        ],
      ];
    },
    [organizationOptions, providerTypeOptions, organizations],
  );
}

export default useGenerateFields;
