import ExpertUserService from '@api/ExpertUserService';
import TextFieldItem from '@components/TextFieldItem';
import { ExpertRole } from '@declarations/common/enums/ExpertRole';
import { UserType } from '@declarations/common/enums/UserType';
import { ReportTypeEnum } from '@declarations/common/reportType';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

type ExpertModalState = {
  newRow: any;
  oldRow: any;
  expertRole: ExpertRole;
  reportType: ReportTypeEnum;
  reject: (row: any) => void;
  resolve: (row: any) => void;
};

const optionMapper = (item: any) => ({
  value: JSON.stringify({ id: item.id, name: `${item.firstname} ${item.lastname}` }),
  label: `${item.firstname} ${item.lastname}`,
});

export default function useExpertUsers(userType?: UserType) {
  const [clinicalReviewers, setClinicalReviewers] = useState<any[]>([]);
  const [legalReviewers, setLegalReviewers] = useState<any[]>([]);
  const [note, setNote] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [promiseArguments, setPromiseArguments] = useState<ExpertModalState | null>(null);
  const [usersLoading, setUsersLoading] = useState(true);

  const closeModal = () => {
    setPromiseArguments(null);
    setNote(undefined);
    setLoading(false);
  };

  useEffect(() => {
    if (userType !== UserType.SuperAdmin) {
      setUsersLoading(false);
      return;
    }

    ExpertUserService.get().then((response: any) => {
      const clinicalReviewerOptions = (response.data as any)
        .filter((item: any) => item.expertRole === ExpertRole.ClinicalReviewer || item.expertRole === ExpertRole.IntegratedReviewer)
        .map(optionMapper);
      setClinicalReviewers([{ value: '', label: <em>None</em> }, ...clinicalReviewerOptions]);

      const legalReviewerOptions = (response.data as any)
        .filter((item: any) => item.expertRole === ExpertRole.LegalReviewer || item.expertRole === ExpertRole.IntegratedReviewer)
        .map(optionMapper);
      setLegalReviewers([{ value: '', label: <em>None</em> }, ...legalReviewerOptions]);
      setUsersLoading(false);
    });
  }, []);

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments as ExpertModalState;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    closeModal();
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve, expertRole, reportType } =
      promiseArguments as ExpertModalState;

    try {
      const clinicalReviewerId = newRow.clinicalReviewer && JSON.parse(newRow.clinicalReviewer)?.id
      const legalReviewerId = newRow.legalReviewer && JSON.parse(newRow.legalReviewer)?.id
      setLoading(true);
      await toast.promise(
        ExpertUserService.upsertReportAssignments({
          clinicalReviewers: clinicalReviewerId ? [clinicalReviewerId] : [],
          legalReviewers: legalReviewerId ? [legalReviewerId] : [],
          reportId: newRow.id,
          reportType,
          note,
        }),
        {
          pending: 'Updating...',
          success: 'Successfully updated',
          error: 'Something went wrong',
        },
      );

      resolve(newRow);
      closeModal();
    } catch (error) {
      console.error(error);
      reject(oldRow);
      closeModal();
    }
  };

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value);

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    return (
      <Dialog fullWidth maxWidth="sm" open={!!promiseArguments}>
        <DialogTitle>Confirm assignment</DialogTitle>
        <DialogContent dividers>
          <TextFieldItem
            fullWidth
            multiline
            label="Note"
            value={note}
            onChange={handleNoteChange}
            minRows={3}
            inputProps={{ maxLength: 180 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} disabled={loading}>SAVE</Button>
          <Button onClick={handleNo}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return {
    clinicalReviewers,
    legalReviewers,
    promiseArguments,
    usersLoading,
    setPromiseArguments,
    renderConfirmDialog,
  };
}
